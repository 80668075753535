<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        {{ partner_id == undefined ? "Add Partner" : "Edit Partner" }}
      </h1>
      <!-- <h1 class="mt-5 mb-5" style="color: #3F4254">Edit Partner</h1> -->
    </div>
    <div class="pt-5 pl-5 pr-5">
      <div class="d-flex">
        <v-col cols="6">
          <div>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="Name"
            >
              <b-form-input
                v-model="name_input"
                size="sm"
                placeholder="Enter text"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="Phone"
            >
              <b-form-input
                v-model="phone_input"
                type="number"
                size="sm"
                placeholder="Enter text"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="Address"
            >
              <b-form-input
                v-model="address_input"
                size="sm"
                placeholder="Enter text"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="Description"
              label-for="textarea"
            >
              <Editor v-model="description_input"></Editor>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="Aplly all programme"
            >
              <v-switch
                class="mt-0 pt-0 ml-2"
                v-model="all_all_programme"
                :label="`${all_all_programme.toString().toUpperCase()}`"
              ></v-switch>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label-size="lg"
              label="Avatar"
            >
              <b-form-file
                @change="onAvatarChange"
                accept="image/*"
              ></b-form-file>
              <div style="width: 50%" v-if="avatar_data != ''">
                <b-img
                  class="mt-1"
                  rounded
                  height="200px"
                  :src="avatar_data"
                ></b-img>
              </div>
            </b-form-group>
          </div>
        </v-col>

        <v-col cols="6">
          <div>
            <v-btn
              rounded
              color="#3699FF"
              x-small
              large
              class="float-right mt-2 mb-2"
              @click="openDialogAddProgramme"
            >
              <v-icon color="white" dark>mdi-file</v-icon
              ><span style="color: white">Add Programme</span>
            </v-btn>
          </div>
          <div class="table table-responsive">
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Cover</th>
                  <th scope="col">Name</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!all_all_programme">
                  <template v-for="(programme, i) in programmes">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <v-img
                          v-if="programme.cover"
                          :src="programme.cover.vi"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <template v-if="programme.name">
                          <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >
                            {{ programme.name.vi }}
                          </p>
                        </template>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteProgramme(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete Programme</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!programmes.length">
                    <tr>
                      <td style="text-align: center" colspan="4">No data</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td style="text-align: center" colspan="4">
                      Áp dụng tất cả các chương trình
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-col>
      </div>
      <v-col cols="12">
        <center>
          <v-btn
            class="btn font-weight-bold text-uppercase"
            x-large
            color="primary"
            @click="submit"
            >Submit</v-btn
          >
        </center>
      </v-col>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogAddProgramme" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Programme</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddProgramme = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">Programmes</label>
                  <v-autocomplete
                    v-model="programme_ids"
                    :items="programme_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name.vi"
                    item-value="id"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddProgramme"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAddProgramme = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
export default {
  name: "FormPartner",
  components: {
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  props: ["partner_id"],
  data() {
    return {
      name_input: "",
      phone_input: "",
      address_input: "",
      description_input: "",
      avatar_data: "",
      avatar_file: null,
      programmes: [],
      programme_tags: [],
      programme_ids: [],
      dialogAddProgramme: false,
      all_all_programme: false,
    };
  },
  watch: {},
  created() {
    this.getAllProgramme();
    this.setInputData();
  },
  methods: {
    async setInputData() {
      if (this.partner_id !== undefined) {
        let vm = this;
        await ApiService.get("prep-app/partner/" + this.partner_id).then(
          function (response) {
            if (response.status === 200) {
              vm.name_input = response.data.name;
              vm.address_input = response.data.address;
              vm.avatar_data = response.data.avatar;
              vm.description_input =
                response.data.description == null
                  ? ""
                  : response.data.description;
              vm.phone_input = response.data.phone;
              vm.programme_ids = response.data.programmes.map((e) => {
                return e.id;
              });
              vm.programmes = response.data.programmes;
              vm.all_all_programme = response.data.is_apply_all_programme === 1;
              console.log(response.data.is_apply_all_programme);
            }
          }
        );
      }
    },
    onAvatarChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        this.avatar_data = "";
        this.avatar_file = null;
        return;
      }
      this.avatar_file = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        vm.avatar_data = e.target.result;
      };
    },
    async getAllProgramme() {
      let seft = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          seft.programme_tags = response.data;
        }
      });
    },
    openDialogAddProgramme() {
      this.dialogAddProgramme = true;
    },
    removeProgrammeTag(item) {
      let index = this.programme_ids.indexOf(item.id);
      if (index >= 0) this.programme_ids.splice(index, 1);
    },
    btnAddProgramme() {
      let data = [];
      for (
        let iProgramme = 0;
        iProgramme < this.programme_tags.length;
        iProgramme++
      ) {
        for (let x = 0; x < this.programme_ids.length; x++) {
          if (this.programme_tags[iProgramme].id === this.programme_ids[x]) {
            data.push(this.programme_tags[iProgramme]);
          }
        }
      }
      this.programmes = [...data];
      this.dialogAddProgramme = false;
    },
    deleteProgramme(i) {
      this.programmes.splice(i, 1);
      this.programme_ids.splice(i, 1);
    },
    submit() {
      if (this.name_input === "") {
        this.$toasted.error("Name is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      // let data = {
      //   name : this.name_input,
      // }
      // if(this.phone_input != '') data.phone             = this.phone_input;
      // if(this.address_input != '') data.address         = this.address_input;
      // if(this.description_input != '') data.description = this.description_input;
      // if(this.programme_ids.length) data.programme_ids  = this.programme_ids;
      // if (this.avatar_file != null) {
      //   data.avatar = this.avatar_file
      // }
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("is_apply_all_programme", this.all_all_programme ? 1 : 0);
      if (this.phone_input !== "") formData.append("phone", this.phone_input);
      if (this.address_input !== "")
        formData.append("address", this.address_input);
      if (this.description_input !== "")
        formData.append("description", this.description_input);
      if (this.programme_ids.length)
        formData.append("programme_ids", this.programme_ids);
      if (this.avatar_file != null) formData.append("avatar", this.avatar_file);
      this.$emit("submitForm", formData);
    },
  },
  beforeDestroy() {
    this.name_input = "";
    this.address_input = "";
    this.avatar_data = null;
    this.description_input = "";
    this.phone_input = "";
    this.programme_ids = [];
    this.programmes = [];
    this.all_all_programme = false;
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
